import SocialLinks from "./SocialLinks";
import { currentYear, firstName, lastName } from "../data";
import "../styles/Footer.css";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="row dark-background">
        <SocialLinks />
      </div>
      <div className="row footer__text">
        &copy; Copyright {currentYear}. Made by {firstName} {lastName}
      </div>
    </footer>
  );
};

export default Footer;
