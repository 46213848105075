import { about } from "../../data";
import Category from "../../components/Category";
import AboutItems from "../../components/AboutItems";
import "../../styles/About.css";

const About = () => {
  return (
    <section id="about" className="section-padding container">
      <h2 className="heading-sec">
        <span className="heading-sec__main">About me</span>
        <span className="heading-sec__sub">Get to know me better</span>
      </h2>
      <div className="row">
        {about.map((aboutItems) => {
          const { id, category, items } = aboutItems;
          return (
            <Category key={id} category={category} prefix="about">
              <AboutItems items={items} />
            </Category>
          );
        })}
      </div>
    </section>
  );
};

export default About;