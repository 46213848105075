import { useState } from "react";
import ShowMoreButton from "./ShowMoreButton";

const TimelineRoleDesc = ({ description }) => {
  const [showMore, setShowMore] = useState(false);
  return (
    <>
      {showMore ? (
        description.map((text, idx) => {
          return <li key={idx}>{text}</li>;
        })
      ) : (
        <li>
          {description.length > 1
            ? `${description.slice(0, 1)}...`
            : description}
        </li>
      )}

      {description.length > 1 ? (
        <li>
          <ShowMoreButton showMore={showMore} setShowMore={() => setShowMore(!showMore)} />
        </li>
      ) : "" }
    </>
  );
};

export default TimelineRoleDesc;
