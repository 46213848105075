import { experience } from "../../data";
import TimelineItem from "../../components/TimelineItem";
import "../../styles/Experience.css";

function Experience() {
  return (
    <section id="experience" className="experience section-padding container">
      <h2 className="heading-sec">
        <span className="heading-sec__main">Experience</span>
      </h2>

      <div className="row">
        <div className="work-experience flexible-column-8">
          {experience
            .slice(0)
            .reverse()
            .map((item) => {
              return (
                <TimelineItem
                  key={item.id}
                  item={item}
                  role={item.role}
                  roleDesc={item.roleDescription}
                  location={item.location}
                  organization={item.companyName}
                  organizationDesc={item.companyDescription}
                />
              );
            })} 
        </div>
      </div>
    </section>
  );
}

export default Experience;
