const ShowMoreButton = ({ showMore, setShowMore }) => {
  return (
    <button
      className="btn show-more"
      style={{ padding: "0.3rem 0.5rem", marginLeft: "0 !important" }}
      onClick={setShowMore}
    >
      {showMore ? "Show less" : "Show more"}
    </button>
  );
};

export default ShowMoreButton;
