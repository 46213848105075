import { useDispatch, useSelector } from "react-redux";
import { closeModal } from "../features/modal/modalSlice";
import { FaTimes } from "react-icons/fa";
import "../styles/Modal.css";

const Modal = () => {
  const { isOpen, modalHeader, modalMessage } = useSelector(
    (store) => store.modal
  );
  const dispatch = useDispatch();

  return (
    <div className={isOpen ? "modal-overlay show-modal" : "modal-overlay"}>
      <div className="modal-container">
        <h3 className="modal-header">{modalHeader}</h3>
        <div className="modal-body">
          {modalMessage ? (
            <p className="modal-message ">{modalMessage}</p>
          ) : (
            <div role="status">
              <span>Loading...</span>
            </div>
          )}
        </div>

        <button className="modal-close-btn" onClick={() => dispatch(closeModal())}>
          <FaTimes />
        </button>
      </div>
    </div>
  );
};

export default Modal;
