import { HashLink as Link } from "react-router-hash-link";
import { LuChevronUp } from "react-icons/lu";
import '../styles/BackToTop.css';

const BackToTop = ({ showScrollToTop }) => {
  return (
    showScrollToTop && (
      <Link to="#" smooth className="btn back-to-top">
        <LuChevronUp className="up-icon" />
      </Link>
    )
  );
};

export default BackToTop;
