import { useSelector } from "react-redux";
import { contactSubheading } from "../../data";
import ContactForm from "../../components/ContactForm";
import Modal from "../../components/Modal";
import Map from "../../components/Map";
import "../../styles/Contact.css";

const Contact = () => {
  const {isOpen} = useSelector((store) => store.modal)

  return (
    <section id="contact" className="section-padding container">
      <h2 className="heading-sec">
        <span className="heading-sec__main">Contact me</span>
        <span className="heading-sec__sub">
          <p>{contactSubheading}</p>
        </span>
      </h2>
      <div className="row">
        <div className="flexible-column-8 grid-lg-half">
          <div className="contact__column send-message">
            <ContactForm />
            { isOpen && <Modal /> }
          </div>
          <div className="contact__column">
            <Map />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
