import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  isOpen: false,
  modalHeader: "",
  modalMessage: "",
};

const modalSlice = createSlice({
  name: "modal",
  initialState,
  reducers: {
    setHeader: (state, action) => {
      state.modalHeader = action.payload
    },
    setMessage: (state, action) => {
      state.modalMessage = action.payload
    },
    openModal: (state) => {
      state.isOpen = true;
    },
    closeModal: (state) => {
      state.isOpen = false;
    },
  },
});

export default modalSlice.reducer;
export const { openModal, closeModal, setHeader, setMessage } = modalSlice.actions;
