import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { useMemo } from "react";
import { currentLocation, email } from "../data";
import { LuMail, LuMapPin } from "react-icons/lu";
import "../styles/Contact.css";

const Map = () => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  });

  const center = useMemo(() => ({ lat: 42.4328, lng: 24.6419 }), []);

  return (
    <div className="contact__column-map">
      <div className="contact__info">
        <span className="icon__wrapper">
          <LuMail className="icon" />
        </span>
        <div>
          <p>{email}</p>
        </div>
      </div>
      <div className="contact__info">
        <span className="icon__wrapper">
          <LuMapPin className="icon" />
        </span>
        <p>{currentLocation}</p>
      </div>

      <div className="map">
        {!isLoaded ? (
          <h1>Loading...</h1>
        ) : (
          <GoogleMap
            zoom={7}
            center={center}
            mapContainerClassName="map-container"
          >
            <MarkerF position={{ lat: 42.6983, lng: 23.3199 }} />
          </GoogleMap>
        )}
      </div>
    </div>
  );
};

export default Map;
