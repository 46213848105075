import { LuMapPin } from "react-icons/lu";
import TimelineRoleDesc from "./TimelineRoleDesc";
import "../styles/TimelineItem.css";

const TimelineItem = ({
  item,
  role,
  roleDesc,
  location,
  organization,
  organizationDesc,
}) => {
  const { start, end } = item;
  return (
    <div className="timeline-item">
      <h4 className="timeline-item__role">{role}</h4>

      <div className="timeline-item__period-location">
        <h5>
          {start} - {end}
        </h5>
        {location ? (
          <p>
            <LuMapPin />
            {location}
          </p>
        ) : (
          ""
        )}
      </div>

      {organization ? (
        <div className="timeline-item__organization">
          <h5 className="organization__name">{organization}</h5>

          {organizationDesc ? (
            <p className="organization__desc">{organizationDesc}</p>
          ) : (
            ""
          )}
        </div>
      ) : (
        ""
      )}

      {roleDesc ? (
        <>
          <ul className="timeline-item__role-desc">
            <TimelineRoleDesc description={roleDesc} />
          </ul>
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default TimelineItem;
