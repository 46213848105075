import { useRef, useState } from "react";
import { HashLink as Link } from "react-router-hash-link";
import { FaBars } from "react-icons/fa";
import { links } from "../data";
import logo from "../assets/logo.svg";
import "../styles/Header.css";

const Header = () => {
  const [showLinks, setShowLinks] = useState(false);
  const linksContainerRef = useRef(null);
  const linksRef = useRef(null);

  const linkStyles = {
    height: showLinks
      ? `${linksRef.current.getBoundingClientRect().height}px`
      : "0px",
  };

  const toggleLinks = () => {
    setShowLinks(!showLinks);
  };

  return (
    <header className="header">
      <nav className="navigation">
        <div className="nav-center">
          <div className="nav-header">
            <Link to="#home" smooth>
              <img
                src={logo}
                alt="plemena-personal-logo"
                height="40px"
                width="40px"
              />
            </Link>
            <button
              className={showLinks ? "nav-toggle-rotate nav-toggle" : "nav-toggle"}
              onClick={toggleLinks}
              type="checkbox"
            >
              <FaBars />
            </button>
          </div>

          <div
            className="nav-links-container"
            ref={linksContainerRef}
            style={linkStyles}
          >
            <ul className="nav-links" ref={linksRef}>
              {links.map((link) => {
                const { id, dest, text } = link;
                return (
                  <li key={id} onClick={toggleLinks}>
                    <Link to={dest} smooth>
                      {text}
                    </Link>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      </nav>
    </header>
  );
};

export default Header;
