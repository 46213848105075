import { useRef, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import { setMessage, setHeader, openModal } from "../features/modal/modalSlice";
import {
  error,
  success,
  successfullySentMessage,
  unsuccessfullySentMessage,
} from "../data";
import emailjs from "@emailjs/browser";
import ReCAPTCHA from "react-google-recaptcha";
import "../styles/Contact.css";

const ContactForm = () => {
  const form = useRef(null);
  const recaptchaRef = useRef(null);
  const [recaptchaError, setRecaptchaError] = useState("");
  const dispatch = useDispatch();

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm();

  const handleReCaptchaChange = () => {
    if (recaptchaRef.current) {
      setRecaptchaError(null);
    }
  };

  const onSubmit = () => {
    const recaptchaValue = recaptchaRef.current.getValue();
    if (!recaptchaValue) {
      setRecaptchaError("Verify you're a human");
    } else {
      emailjs
        .sendForm(
          "contact_service",
          "contact_form",
          form.current,
          "T8Yay5rXOxBQYT-4G"
        )
        .then(
          () => {
            dispatch(setHeader(success));
            dispatch(setMessage(successfullySentMessage));
            resetForm();
          },
          () => {
            dispatch(setHeader(error));
            dispatch(setMessage(unsuccessfullySentMessage));
            resetForm();
          }
        );
       dispatch(openModal());
    }
  };

  const resetForm = () => {
    reset();
    setRecaptchaError(null);
    recaptchaRef.current.reset();
  };

  return (
    <form
      ref={form}
      onSubmit={handleSubmit(onSubmit)}
      autoComplete="off"
      className="contact__column-form"
    >
      <div className="contact__from-field">
        <input
          type="text"
          name="from_name"
          className={
            (errors.name ? "is-invalid m-0" : "") + " contact__from-input"
          }
          id="name-label"
          {...register("name", {
            required: { value: true, message: "Name is required" },
            maxLength: {
              value: 15,
              message: "Name can be maximum 15 symbols",
            },
          })}
        />
        {errors.name && (
          <div className="invalid-feedback">{errors.name.message}</div>
        )}
        <label htmlFor="name-label">Your name</label>
      </div>

      <div className="contact__from-field">
        <input
          type="text"
          className={
            (errors.email ? "is-invalid m-0" : "") +
            " " +
            "contact__from-input my-3"
          }
          id="email"
          name="from_email"
          {...register("email", {
            required: {
              value: true,
              message: "Email is required",
            },
            pattern: {
              value:
                // eslint-disable-next-line
                /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
              message: "You must provide valid email",
            },
          })}
        />
        {errors.email && (
          <div className="invalid-feedback">{errors.email.message}</div>
        )}
        <label htmlFor="email">Your email</label>
      </div>

      <div className="contact__from-field">
        <input
          type="text"
          name="subject"
          className={
            (errors.subject ? "is-invalid m-0" : "") +
            " " +
            "contact__from-input"
          }
          id="subject"
          {...register("subject", {
            required: { value: true, message: "Subject is required" },
          })}
        />
        {errors.subject && (
          <div className="invalid-feedback">{errors.subject.message}</div>
        )}
        <label htmlFor="subject">Subject</label>
      </div>

      <div className="contact__from-field">
        <textarea
          name="message"
          id="message"
          className={
            (errors.message ? "is-invalid m-0" : "") +
            " " +
            "contact__from-input"
          }
          rows="4"
          {...register("message", {
            required: {
              value: true,
              message: "Message is required",
            },
            maxLength: {
              value: 2000,
              message: "Your message should be up to 2000 symbols",
            },
          })}
        ></textarea>
        {errors.message && (
          <div className="invalid-feedback">{errors.message.message}</div>
        )}
        <label htmlFor="message">Message</label>
      </div>

      <ReCAPTCHA
        ref={recaptchaRef}
        className={
          "contact__from-field" + (recaptchaError ? " is-invalid m-0" : "")
        }
        sitekey={process.env.REACT_APP_GOOGLE_RECAPTCHA_SITE_KEY}
        onChange={handleReCaptchaChange}
      />
      {recaptchaError ? (
        <div className="invalid-feedback recaptcha">{recaptchaError}</div>
      ) : (
        ""
      )}

      <div className="btn-container justify-content-start">
        <button className="btn" type="submit">
          Send
        </button>
      </div>
    </form>
  );
};

export default ContactForm;
