import DescriptionItem from "./DescriptionItem";

const Description = ({ data }) => {
  return (
    <div className="description">
      {data.map((descItem) => {
        const { id, text } = descItem;
        return (
          <DescriptionItem key={id} text={text} isLast={id === data.length} />
        );
      })}
    </div>
  );
};

export default Description;
