import Header from "./Header";
import Main from "./Main";
import Footer from "./Footer";
import BackToTop from "./BackToTop";
import { BrowserRouter } from "react-router-dom";
import { useEffect, useState } from "react";

function App() {
  const [showScrollToTop, setShowScrollTop] = useState(false);

  useEffect(() => {
    const handleScrollToTopButtonVisibility = () => {
      window.scrollY > 0 ? setShowScrollTop(true) : setShowScrollTop(false);
    };

    window.addEventListener("scroll", handleScrollToTopButtonVisibility);

    return () => {
      window.removeEventListener("scroll", handleScrollToTopButtonVisibility);
    };
  });

  return (
    <BrowserRouter>
      <div className="App">
        <Header />
        <Main />
        <Footer />
        <BackToTop showScrollToTop={showScrollToTop} />
      </div>
    </BrowserRouter>
  );
}

export default App;
