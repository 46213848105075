const SkillsItems = ({ items }) => {
  return (
    <div className="skills-section__main card-body pt-0 ">
      <ul className="nav flex-colum nav-pills skills-section__items">
        {items.map((skill) => {
          return <li key={skill.id}>{skill.text}</li>;
        })}
      </ul>
    </div>
  );
};

export default SkillsItems;
