import { HashLink as Link } from "react-router-hash-link";
import { Typewriter } from "react-simple-typewriter";
import { firstName, typewriter, description } from "../../data";
import photo from "../../assets/personal-photo.jpg";
import resume from "../../assets/Plamena-Mihaylova-Resume.pdf";
import Description from "../../components/Description";
import SocialLinks from "../../components/SocialLinks";
import "../../styles/Home.css";


const Home = () => {
  return (
    <section id="home" className="home container">
      <div className="row">
        <div className="flexible-column-8">
          <img
            src={photo}
            className="personal-photo"
            alt="Plamena Mihaylova"
            width="400"
            height="400"
            loading="lazy"
          />
        </div>
        <div className="flexible-column-8">

          <h1 className="heading-first">
            <div className="heading-first__main">
              <span>
                Hello, I'm <b>{firstName}</b>
              </span>
            </div>
            <div className="heading-first__sub">
              <Typewriter
                words={typewriter}
                loop={true}
                typeSpeed={50}
                deleteSpeed={50}
                delaySpeed={1800}
                cursor
                cursorBlinking={true}
              />
            </div>
          </h1>

          <Description data={description} />

          <div className="btn-container justify-content-center">
            <Link to="#contact" smooth className="btn mx-lg-2">
              Contact me
            </Link>
            <a
              className="btn mx-lg-2"
              target="_blank"
              rel="noreferrer"
              href={resume}
              download="Plamena-Mihaylova-Resume"
            >
              Download CV
            </a>
          </div>
        </div>
      </div>

      <div className="row">
        <div className="social-links light-background">
          <SocialLinks />
        </div>
      </div>
    </section>
  );
};

export default Home;
