const AboutItems = ({ items }) => {
  return (
    <div className="category__content grid-md-half">
      {items.map((item) => {
        const { id, title, text } = item;
        return (
          <div key={id} className="row">
            <div className="category__item ">
              <h4 className="category__item__title">{title}</h4>
              <p className="category__item__text">{text}</p>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AboutItems;
