import { skills } from "../../data";
import Category from "../../components/Category";
import SkillsItems from "./SkillsItems";
import "../../styles/Skills.css";

function Skills() {
  return (
    <section id="skills" className="section-padding container">
      <h2 className="heading-sec">
        <span className="heading-sec__main">Skills</span>
      </h2>
      <div className="row">
        {skills.map((skillsItem) => {
          const { id, category, items } = skillsItem;
          return (
            <Category key={id} category={category} prefix="skills">
              <SkillsItems items={items} />
            </Category>
          );
        })}
      </div>
    </section>
  );
}

export default Skills;