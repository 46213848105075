import {
  FaBehance,
  FaLinkedinIn,
  FaGithub,
  FaGoodreadsG,
  FaCodepen,
} from "react-icons/fa";

// NAVIGATION
export const links = [
  {
    id: 1,
    dest: "#home",
    text: "home",
  },
  {
    id: 2,
    dest: "#experience",
    text: "experience",
  },
  {
    id: 3,
    dest: "#skills",
    text: "skills",
  },
  {
    id: 4,
    dest: "#education",
    text: "education",
  },
  {
    id: 5,
    dest: "#about",
    text: "about",
  },
  {
    id: 6,
    dest: "#contact",
    text: "contact",
  },
];

// HOME
export const firstName = "Plamena";
export const lastName = "Mihaylova";
export const typewriter = [
  "Full-Stack Developer",
  "Designer in the making",
  "Lifelong learner",
  "Avid reader",
];

export const description = [
  {
    id: 1,
    text: "Self-taught programmer with versatile software development experience and a background in telecommunications.",
  },
  { id: 2, text: "Tech enthusiast seeking modern technologies." },
  {
    id: 3,
    text: "Front-end diva weaving together a passion for front-end development and design expertise.",
  },
];

export const socialLinks = [
  {
    id: 1,
    url: "https://www.linkedin.com/in/plamena-mihaylova/",
    icon: <FaLinkedinIn />,
  },
  {
    id: 2,
    url: "https://github.com/plamenamihaylova",
    icon: <FaGithub />,
  },
  {
    id: 3,
    url: "https://www.goodreads.com/user/show/31439748-plamena",
    icon: <FaGoodreadsG />,
  },
  {
    id: 4,
    url: "https://codepen.io/plamenamihaylova/collections/",
    icon: <FaCodepen />,
  },
  {
    id: 5,
    url: "https://www.behance.net/plamenamihaylova",
    icon: <FaBehance />,
  },
];

// EXPERIENCE
export const experience = [
  {
    id: 1,
    companyName: "A1",
    companyDescription:
      "A1 is a leading provider of digital services and telecommunication solutions in the Bulgarian market",
    start: "07/2014",
    end: "01/2015",
    location: "Sofia, Bulgaria",
    role: "Trainee",
    roleDescription: [
      "Member of the Network Infrastructure and Fixed Services Department",
      "Gained practical knowledge of mobile network principles through collaborative learning and guidance from experienced colleagues",
      "Contributed to the accurate and up-to-date management of the company's database, efficiently updating and creating records for telecom's sites (cell towers)",
      "Conducted on-site visits to cell towers, showcasing a commitment to understanding the practical aspects of network infrastructure",
    ],
  },
  {
    id: 2,
    companyName: "Telepoint",
    companyDescription:
      "Telepoint is leading operating Tier 3 Carrier Neutral Data Center",
    start: "07/2015",
    end: "01/2017",
    location: "Sofia, Bulgaria",
    role: "Network Operation Center Engineer",
    roleDescription: [
      "Monitored critical parameters of the data center using a wide range of alarming and detection systems, responding promptly to resolve any issues and ensure optimal performance",
      "Provisioned, tested, and troubleshooted physical network connectivity, both with copper and optical fibers",
      "Conducted fusion splicing of optical fibers with precision, ensuring reliable and efficient network connectivity",
      "Successfully installed, set up, maintained, and troubleshooted servers and networking equipment, ensuring smooth operations and minimal downtime",
      "Utilized fundamental network and system administration skills to configure switching and routing in IP networks",
      "Provided customer support, effectively addressing clients' requests and inquiries via phone, email, and on-site assistance, ensuring high levels of customer satisfaction",
      "Participated in training new employees, sharing knowledge to foster a skilled and capable team",
    ],
  },
  {
    id: 3,
    companyName: "Telco Systems",
    companyDescription:
      "Telco Systems is a global leader in innovative Edge Compute and Virtualization platforms, and Carrier Ethernet solutions",
    start: "01/2017",
    end: "01/2019",
    location: "Sofia, Bulgaria",
    role: "Junior Software Engineer",
    roleDescription: [
      "Member of the Cloud and Virtualization team, contributing to the development of an NFV ARM Solution",
      "Implemented operational networking logic for NFV devices, using Linux and OpenStack",
      "Developed CLI using Bash and Python to enhance user interaction and system management",
      "Adapted to diverse environments, working with Ubuntu and Fedora Linux distributions",
      "Creation and customization of Debian packages",
    ],
  },
  {
    id: 4,
    companyName: "Fadata",
    companyDescription:
      "Fadata stands as a leading provider of next-generation core insurance platform software solution (INSIS), serving companies worldwide",
    start: "01/2019",
    end: "01/2021",
    location: "Sofia, Bulgaria / Remote",
    role: "Java Software Developer",
    roleDescription: [
      "Developed and extended business solutions for the INSIS web application using Oracle ADF Framework",
      "Contributed to both client projects customizing the core INSIS version to meet the unique needs of different clients, and core INSIS platform development, showcasing versatility and adaptability in diverse tasks.",
      "Utilized JSP and JSF technologies for web development, enhancing user interfaces and user experience",
      "Learned to navigate and work with legacy code base",
      "Led client training sessions, empowering them to effectively utilize the INSIS platform and its features",
    ],
  },
  {
    id: 5,
    companyName: "Quantive",
    companyDescription:
      "Quantive is the world’s leading Strategy Execution Platform based on the OKR (Objectives and Key Results) management methodology",
    start: "03/2021",
    end: "08/2022",
    location: "Sofia, Bulgaria / Remote",
    role: "Software Developer",
    roleDescription: [
      "Member of the data ETL (Extract, Transform, Load) team",
      "Worked with Apache Camel during the initial three months on integrating and consuming data from diverse sources",
      "Incorporated new data source connectors to the platform based on JDBC drivers provided by CData, expanding the platform's data integration capabilities.",
      "Transitioned into front-end development and became the go-to front-end developer for the team",
      "Developed functional and robust web pages and components with TypeScript, AngularJS, Less, and Pug",
      "Independently developed crucial front-end functionality for one of the company's major clients, showcasing autonomy and proficiency in delivering client-focused solutions",
      "Effectively addressed backend bugs in Go when required",
      "Took part in the migration from AngularJS to Angular in the last two months in the company",
      "Actively contributed to code reviews and codebase maintenance",
    ],
  },
  {
    id: 6,
    start: "08/2022",
    end: "01/2024",
    role: "Career Break",
    roleDescription: [
      "Prioritized mental health and well-being, successfully recovering from burnout",
      "Collaborated with a professional to identify and address the root causes of burnout",
      "Pursued personal sports-related goal, addressing knee pain through targeted exercises, culminating in participation in a 10K run. Currently working towards performing a pistol squat.",
      "Advanced as a front-end developer by participating in various courses, self-guided learning, and working on several personal projects",
      "Embraced the opportunity to pursue a passion for graphic design, enrolling in a structured learning path at SoftUni Creative",
    ],
  },
  {
    id: 7,
    companyName: "SAP",
    companyDescription:
      "SAP helps the world run better and improve people’s lives.",
    start: "01/2024",
    end: "Ongoing",
    location: "Sofia, Bulgaria / Hybrid",
    role: "Full-Stack Developer",
    roleDescription: [
      "Contribute to applications and services on SAP's Corporate Data Platforms, to help the company getting the most value from data",
      "Develop and maintain solutions on top of SAP Business Technology Platform",
    ],
  },
];

// SKILLS
export const skills = [
  {
    id: 1,
    category: "Programming languages and technologies",
    items: [
      { id: 1, text: "Java" },
      { id: 2, text: "JavaScript" },
      { id: 3, text: "TypeScript" },
      { id: 4, text: "Python" },
      { id: 5, text: "Go" },
      { id: 6, text: "Node.js" },
      { id: 7, text: "jQuery" },
      { id: 8, text: "HTML" },
      { id: 9, text: "CSS" },
      { id: 10, text: "Bootstrap" },
      { id: 11, text: "Sass" },
      { id: 12, text: "Less" },
    ],
  },
  {
    id: 2,
    category: "Frameworks",
    items: [
      { id: 1, text: "React" },
      { id: 2, text: "AngularJS" },
      { id: 3, text: "Angular" },
      { id: 4, text: "Spring" },
      { id: 5, text: "Oracle ADF" },
      { id: 6, text: "SAP CAP"},
      { id: 7, text: "SAPUI5"},
    ],
  },
  {
    id: 3,
    category: "Databases",
    items: [
      { id: 1, text: "MySQL" },
      { id: 2, text: "PostgreSQL" },
      { id: 3, text: "MongoDB" },
      { id: 4, text: "Oracle" },
    ],
  },
  {
    id: 4,
    category: "Tools",
    items: [
      { id: 1, text: "Jira" },
      { id: 2, text: "Git" },
      { id: 3, text: "Bitbucket" },
      { id: 4, text: "Github" },
      { id: 5, text: "VS Code" },
      { id: 6, text: "IntelliJ IDEA" },
      { id: 7, text: "Bash" },
      { id: 8, text: "Vim" },
      { id: 9, text: "Jenkins" },
    ],
  },
];

// EDUCATION
export const education = [
  {
    id: 1,
    schoolName: "Romain Rolland Foreign Language High School - Stara Zagora",
    degree: "Secondary school",
    start: "09/2007",
    end: "05/2012",
    activitiesDescription: ["Languages - English and German"],
  },
  {
    id: 2,
    schoolName: "Technical University - Sofia",
    degree: "Bachelor of Engineering - BE, Telecommunications",
    start: "09/2012",
    end: "09/2016",
    activitiesDescription: [
      "Business Booster Entrepreneurship Training Program",
      "BEST Engineering Week 2014, Vivacom Team",
      "BEST Engineering Week 2015, Interoute Team",
      "Part of radio amateurs university’s club",
    ],
  },
  {
    id: 3,
    schoolName: "Hochschule Fulda",
    degree:
      "Erasmus+ Exchange Program, Developing a thesis for an undergraduate degree",
    start: "05/2016",
    end: "09/2016",
    activitiesDescription: [
      "Thesis: Evaluation of performance of a sensor network in human body",
      "Deutsch Grundkurs (A1) Teil 2",
      "International Summer University (ISU) Fulda",
      "Deutsch Kurs (A2/B1) - ISU Class",
      "International Health Aspects on Stress Management - ISU Class",
    ],
  },
  {
    id: 4,
    schoolName: "Technical University - Sofia ",
    degree:
      "Master of Engineering - MEng, Computer Systems Networking and Telecommunications",
    start: "09/2016",
    end: "02/2018",
    activitiesDescription: [
      "Thesis: Developing a Command Line Interface for Linux based network device",
    ],
  },
  {
    id: 5,
    schoolName: "SoftUni Creative",
    degree: "Graphic Design",
    start: "05/2023",
    end: "Ongoing",
  },
];

// ABOUT
export const about = [
  {
    id: 1,
    category: "Strengths",
    items: [
      {
        id: 1,
        title: "Discipline",
        text: `Throughout my education and work experience, I have learned that all big results are achieved by the small steps I take every day. \n A testament to this mindset, I managed to become a software engineer without formal training or a CS degree.`,
      },
      {
        id: 2,
        title: "Perseverance",
        text: `Perseverance is a trait I didn't realize I possessed until I reflected on my attitude toward almost any aspect of my life. \n I remind myself that things always seem impossible until they're done. \n I always strive becoming a better version of myself and don't settle down for the easy path, embracing challenges as opportunities for growth and achievement.`,
      },
      {
        id: 3,
        title: "Team player",
        text: `Collaboration and collective effort are, in my opinion, the ultimate strengths of humanity. \n I've had the pleasure of working in some great teams where our team efforts brought excellent results.  \n However, I've also experienced a lack of team spirit and saw firsthand how destructive it can be. \n That's why I'm doing my best to contribute to the team efforts and be an active team member.`,
      },
      {
        id: 4,
        title: "Lifelong learner",
        text: `For me, learning is the key to growth and achieving my goals. \n I am forever grateful for my decision to become a software developer, as it constantly reinforces my passion for learning and exploring new things. \n As a developer, I embrace the belief that 'nothing is impossible to learn'. This outlook benefits both my professional and personal life.`,
      },
    ],
  },
  {
    id: 2,
    category: "Weaknesses",
    items: [
      {
        id: 1,
        title: "Perfectionism",
        text: `Perfectionism has done both good and bad things for me. \n It can be challenging to strive for the best and to keep my mental health in check. \n I try to manage my urge to have the best possible outcome from each situation and let go when it affects my well-being. \n I work on this by accepting that I don't have control over everything, and even when I do have, the path is not always smooth or predictable. \n I also try to learn from the hard times instead of worrying about them, as they are often the best teachers.`,
      },
      {
        id: 2,
        title: "Overthinking",
        text: `I am tempted to go into the rabbit hole of overthinking, and it usually does me more harm than good. \n It's hard for me to manage this, but I try to take small conscious steps toward overcoming this. \n For example, I have wanted to create my own site for a long time, but I used to think too much about how I should implement it, what I should include in it, or how I should design it, rather than doing it. I decided to take action instead of just thinking, and the result can be seen literally here.`,
      },
    ],
  },
  {
    id: 3,
    category: "Passions",
    items: [
      {
        id: 1,
        title: "Painting and Graphic Design",
        text: `I am interested in creating visuals through various mediums - painting, making cards, or just beautiful lettering. \n Taking up graphic design has provided an artistic outlet I am passionate about.`,
      },
      {
        id: 2,
        title: "Board games",
        text: `I love interacting with people and board games provide a fun way to connect with others. \n Some of the games I like to play are 'What do you meme', 'Exploding kittens' and 'Activity'.`,
      },
      {
        id: 3,
        title: "Biology, Health, Cooking and Sports",
        text: `I am fascinated by how the human body functions and how everything is tied together, perfectly balanced. \n I believe that by knowing and honoring how our bodies function, we can live the best version of our life. \n I also like practicing different kinds of sports. \n Currently, I am interested in badminton, running, and jumping rope.`,
      },
      {
        id: 4,
        title: "Reading",
        text: `Reading is a hobby I developed thanks to a friend who introduced me to the world of literature. \n We used to go to the theater together, and she would always read the plays and novels beforehand. \n I was fascinated by her enthusiasm, so I started reading more myself. \n Over the years, I have learned a lot from books, not only about fictional characters but about programming as well.`,
      },
    ],
  },
];

// CONTACT
export const contactSubheading = `Have a question or a project in mind? \n I’m all ears. \n Drop me a line anytime.`;

export const currentLocation = `Sofia, Bulgaria`;

export const email = "dev.plamena@gmail.com";

export const success = "Success";
export const successfullySentMessage = `Your message was successfully sent. \n Will try to reach out to you soon. 📬`;
export const error = "Error";
export const unsuccessfullySentMessage = "Failed to send the message, please try again 🚨";

// FOOTER
export let currentYear = new Date().getFullYear();
