const Category = ({ category, prefix, children }) => {
  return (
    <div className={`${prefix}-section flexible-column-8`}>
      <h2 className={`${prefix}-section__category`}>
        <span>{category}</span>
      </h2>
      {children}
    </div>
  );
};

export default Category;
