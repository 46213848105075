import Home from "../views/home/Home";
import Experience from "../views/experience/Experience";
import Skills from "../views/skills/Skills";
import Education from "../views/education/Education";
import About from "../views/about/About";
import Contact from "../views/contact/Contact"

const Main = () => {
  return (
    <main>
      <Home />
      <Experience />
      <Skills />
      <Education />
      <About />
      <Contact />
    </main>
  );
};

export default Main;
