import TimelineItem from "../../components/TimelineItem";
import { education } from "../../data";
import "../../styles/Education.css";

const Education = () => {
  return (
    <section id="education" className="education section-padding container">
      <h2 className="heading-sec">
        <span className="heading-sec__main">Education</span>
      </h2>

      <div className="row">
        <div className="education flexible-column-8">
          {education
            .slice(0)
            .reverse()
            .map((item) => {
              return (
                <TimelineItem
                  key={item.id}
                  item={item}
                  role={item.degree}
                  roleDesc={item.activitiesDescription}
                  organization={item.schoolName}             
                />
              );
            })}
        </div>
      </div>
    </section>
  );
};

export default Education;
