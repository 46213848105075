import { socialLinks } from "../data";
import SocialLink from "./SocialLink";
import "../styles/SocialLinks.css";

const SocialLinks = () => {
  return (
    <div className="flexible-column-full">
      {socialLinks.map((social) => {
        const { id, url, icon } = social;
        return <SocialLink key={id} url={url} icon={icon}/>;
      })}
    </div>
  );
};

export default SocialLinks;
